import React from 'react';

import Divider from './Divider';
import './Skills.css';

const Skills = ({ skills }) => (
    <div className="skills margin-top-3">
        <h3> <i className="fas fa-award header-icon" /> Key skills </h3>
        <Divider />
        <ul className="skills-list">
            { skills.map((s, k) => <li key={k}> { s } </li>)}
        </ul>
    </div>
)

export default Skills;