import React from 'react';

import Assignment from './Assignment';
import './Employment.css';

const Employment = ({ employment }) => (
    <div className="employment"> 
        <h3 className="margin-top-3"> 
            { employment.title }
        </h3>
        <h3 className="font-weight-500">
            <span className="date">
                { employment.start } &nbsp;- &nbsp; 
            </span> 
            { employment.end && (<span className="date">
                { employment.end }
            </span>) }
        </h3>
        <h3 className="font-weight-500">
            { employment.employer.name }
        </h3>
        <div className="margin-top-1 italic">
            { employment.employer.about }
        </div>
        <div className="margin-top-1">
            { employment.description }
        </div>
        { employment.consultancy ? 
            (<div className="assignment-list">
                 { employment.assignments.map((a, key) => <Assignment assignment={a} key={key} />) }
            </div>) : (
            <ul className="achievement-list">
                { employment.achievements.map((a, key) => (
                    <li key={key} className="achievement">{a}</li>
                ))}
            </ul>)
        }
        { !employment.consultancy && <div className="keywords"> 
            <span className="bold">Keywords: </span>
            { employment.keywords.join(', ')} 
        </div> }
    </div>
);

export default Employment;
